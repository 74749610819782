import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import ReactPaginate from 'react-paginate';
import { Zoom, Fade } from "react-awesome-reveal";

import { labels, localeUrls } from '../langs/langs'
import { isInTheFuture } from "../functions/functions";

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnersSlider from "../components/PartnersSlider";
import IntroBanner from "../components/IntroBanner";
import { EventCard, EventCardSmall } from "../components/EventSlider";
import { lSVG, rSVG } from "../components/ArrowLink";

import calendCheckI from '../images/calendar-check.svg';
import eventI from '../images/events.svg'

const NewTitle = ({ localeID }) => <div className="hCon center l">
    <Zoom><img src={eventI}/></Zoom>
    <h2 className="b">{labels[localeID]["NEWEVENTS"]}</h2>
</div>

const ArchiveTitle = ({ localeID }) => <div className="hCon center l">
    <Zoom><img src={calendCheckI}/></Zoom>
    <h2 className="b">{labels[localeID]["ARCHIVE"]}</h2>
</div>

const StoreDetailTemplate = ({ location, data, pageContext: { localeID, totalPages, pageNumber } }) => {

    const stores = data.allEvents.nodes;

    const emptyMsg = <div><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NONEWS"]}</p></div>;

    const handlePageChange = (page) => {
        if (!!page.selected) {
            navigate(localeUrls[localeID]["EVENTS"] + "/" + (page.selected + 1));
            return;
        }
        navigate(localeUrls[localeID]["EVENTS"]);
    }

    let year = 9999;
    let newEvents = true;

    return (
        <Layout location={location} localeID={localeID}>
            <Seo title={labels[localeID]["EVENTS"]} description={labels[localeID]["EVENTS"]} />
            <IntroBanner localeID={localeID} titleKey="EVENTS"/>
            <div className=""></div>
            <div className="hPadSec cardCon newsCon eventCon">
                <div className="content">
                    {!!stores.length ? stores.map((item, index)=>{
                        if (isInTheFuture(item.acfEvents.date)) {
                            if (!index) {
                                return <React.Fragment key={index}>
                                    <NewTitle localeID={localeID}/>
                                    <EventCard item={item} localeID={localeID}/>
                                </React.Fragment>
                            } else {
                                return <EventCard key={index} item={item} localeID={localeID}/>
                            }
                        }

                        if (item.acfEvents.year < year) {
                            year = item.acfEvents.year

                            const heading = (newEvents ? <ArchiveTitle localeID={localeID}/> : null);
                            newEvents = false;

                            return <React.Fragment key={index}>
                                {heading}
                                <Fade><h3 className="b">{item.acfEvents.year}</h3></Fade>
                                <EventCardSmall item={item} localeID={localeID} onMore={()=>{}}/>
                            </React.Fragment>
                        }
                        return <EventCardSmall item={item} key={index} localeID={localeID} onMore={()=>{}}/>
                    }) : emptyMsg}
                </div>
                {!!(totalPages - 1) && <div className="content pages">
                    <ReactPaginate
                        previousLabel={<>{lSVG('#202020')} {labels[localeID]["PREV"]}</>}
                        nextLabel={<>{labels[localeID]["NEXT"]} {rSVG('#202020')}</>}
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        containerClassName={'tablePagination'}
                        forcePage={pageNumber-1}
                    />
                </div>}
            </div>
            <PartnersSlider className="gray" localeID={localeID}/>
        </Layout>
    )
}

export default StoreDetailTemplate

export const pageQuery = graphql`
    query EventsArchiveQuery(
        $category: String!
        $localeID: String!
        $offset: Int!
        $postsPerPage: Int!
    ) {
        allEvents: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: $category}}}}, locale: {locale: {eq: $localeID}}}
            sort: {fields: acfEvents___date, order: DESC}
            limit: $postsPerPage
            skip: $offset
        ) {
            nodes {
                ...EventSliderData
            }
        }
    }
`
